const autoNgTemplateLoaderTemplate1 = require('./edgeGroupsSelector.html');

import angular from 'angular';

angular.module('portainer.edge').component('edgeGroupsSelector', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    model: '<',
    items: '<',
    onChange: '<',
  },
});
